// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-reach-menu-popover] {\n  z-index: var(--z-index-popover);\n}\n\n[data-reach-menu-list] {\n  font: inherit;\n  border: none;\n  border-radius: var(--menu-button-menu-popover-border-radius);\n  box-shadow: var(--box-shadow-1);\n  padding: var(--sp2) 0;\n  background: white;\n}\n\n[data-reach-menu-item] {\n  padding: var(--sp2) var(--sp4);\n}\n\n[data-reach-menu-item][data-selected] {\n  background-color: var(--color-primary-lll);\n  color: inherit;\n}\n\n.MenuButton_menuButtonArrow__3Nvtk {\n  margin-inline-start: var(--sp1);\n}\n\n.MenuButton_menuItemDanger__2YdNx {\n  color: var(--color-danger-600);\n}\n\n.MenuButton_menuItemDanger__2YdNx[data-selected] {\n  background-color: var(--color-danger-000);\n  color: var(--color-danger-600);\n}\n", "",{"version":3,"sources":["webpack://src/components/lib/MenuButton.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,4DAA4D;EAC5D,+BAA+B;EAC/B,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,0CAA0C;EAC1C,cAAc;AAChB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,yCAAyC;EACzC,8BAA8B;AAChC","sourcesContent":["[data-reach-menu-popover] {\n  z-index: var(--z-index-popover);\n}\n\n[data-reach-menu-list] {\n  font: inherit;\n  border: none;\n  border-radius: var(--menu-button-menu-popover-border-radius);\n  box-shadow: var(--box-shadow-1);\n  padding: var(--sp2) 0;\n  background: white;\n}\n\n[data-reach-menu-item] {\n  padding: var(--sp2) var(--sp4);\n}\n\n[data-reach-menu-item][data-selected] {\n  background-color: var(--color-primary-lll);\n  color: inherit;\n}\n\n.menuButtonArrow {\n  margin-inline-start: var(--sp1);\n}\n\n.menuItemDanger {\n  color: var(--color-danger-600);\n}\n\n.menuItemDanger[data-selected] {\n  background-color: var(--color-danger-000);\n  color: var(--color-danger-600);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuButtonArrow": "MenuButton_menuButtonArrow__3Nvtk",
	"menuItemDanger": "MenuButton_menuItemDanger__2YdNx"
};
export default ___CSS_LOADER_EXPORT___;
