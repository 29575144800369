// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._ButtonOrLinkContent_buttonLayout__2lVD1 {\n  display: grid;\n  grid-auto-flow: column;\n  align-items: center;\n  grid-gap: var(--sp2);\n  gap: var(--sp2);\n}\n\n._ButtonOrLinkContent_buttonLayoutWithIconStart__2aHJZ {\n  padding-inline-end: calc(var(--button-font-size) / 2);\n}\n\n._ButtonOrLinkContent_buttonLayoutWithIconEnd__s4inj {\n  padding-inline-start: calc(var(--button-font-size) / 2);\n}\n\n._ButtonOrLinkContent_buttonLayout__2lVD1 svg {\n  font-size: inherit;\n}\n\n._ButtonOrLinkContent_buttonLayoutSm__7HwDy {\n  grid-gap: var(--sp1);\n  gap: var(--sp1);\n}\n", "",{"version":3,"sources":["webpack://src/components/lib/_ButtonOrLinkContent.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,oBAAe;EAAf,eAAe;AACjB;;AAEA;EACE,qDAAqD;AACvD;;AAEA;EACE,uDAAuD;AACzD;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,oBAAe;EAAf,eAAe;AACjB","sourcesContent":[".buttonLayout {\n  display: grid;\n  grid-auto-flow: column;\n  align-items: center;\n  gap: var(--sp2);\n}\n\n.buttonLayoutWithIconStart {\n  padding-inline-end: calc(var(--button-font-size) / 2);\n}\n\n.buttonLayoutWithIconEnd {\n  padding-inline-start: calc(var(--button-font-size) / 2);\n}\n\n.buttonLayout svg {\n  font-size: inherit;\n}\n\n.buttonLayoutSm {\n  gap: var(--sp1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonLayout": "_ButtonOrLinkContent_buttonLayout__2lVD1",
	"buttonLayoutWithIconStart": "_ButtonOrLinkContent_buttonLayoutWithIconStart__2aHJZ",
	"buttonLayoutWithIconEnd": "_ButtonOrLinkContent_buttonLayoutWithIconEnd__s4inj",
	"buttonLayoutSm": "_ButtonOrLinkContent_buttonLayoutSm__7HwDy"
};
export default ___CSS_LOADER_EXPORT___;
