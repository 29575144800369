// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Link_link__2gwgi {\n  cursor: pointer;\n  background: none;\n  color: var(--color-primary);\n  font-weight: 400;\n  font-size: var(--fs2);\n  font-family: var(--ff0);\n  border: none;\n  margin: 0;\n  padding: 0;\n  text-align: start;\n  outline: none;\n  text-decoration: underline;\n}\n\n.Link_linkSm__20syF {\n  font-size: var(--fs1);\n}\n\n.Link_link__2gwgi:hover,\n.Link_link__2gwgi:active {\n  color: var(--color-primary-d);\n}\n", "",{"version":3,"sources":["webpack://src/components/lib/Link.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,2BAA2B;EAC3B,gBAAgB;EAChB,qBAAqB;EACrB,uBAAuB;EACvB,YAAY;EACZ,SAAS;EACT,UAAU;EACV,iBAAiB;EACjB,aAAa;EACb,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;;EAEE,6BAA6B;AAC/B","sourcesContent":[".link {\n  cursor: pointer;\n  background: none;\n  color: var(--color-primary);\n  font-weight: 400;\n  font-size: var(--fs2);\n  font-family: var(--ff0);\n  border: none;\n  margin: 0;\n  padding: 0;\n  text-align: start;\n  outline: none;\n  text-decoration: underline;\n}\n\n.linkSm {\n  font-size: var(--fs1);\n}\n\n.link:hover,\n.link:active {\n  color: var(--color-primary-d);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "Link_link__2gwgi",
	"linkSm": "Link_linkSm__20syF"
};
export default ___CSS_LOADER_EXPORT___;
