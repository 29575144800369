// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonSecondary_buttonSecondary__1_n_P {\n  background: white;\n  color: var(--color-primary);\n  border: 2px solid var(--color-primary);\n  text-transform: var(--button-secondary-text-transform);\n}\n\n.ButtonSecondary_buttonSecondary__1_n_P:focus {\n  box-shadow: var(--form-control-focused-box-shadow);\n}\n\n.ButtonSecondary_buttonSecondary__1_n_P:not(:disabled):active {\n  opacity: 0.8;\n}\n\n@media (hover: hover) {\n  .ButtonSecondary_buttonSecondary__1_n_P:not(:disabled):hover {\n    background-color: var(--color-primary);\n    color: white;\n  }\n\n  .ButtonSecondary_buttonSecondary__1_n_P:not(:disabled):active {\n    opacity: 0.8;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/lib/ButtonSecondary.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,2BAA2B;EAC3B,sCAAsC;EACtC,sDAAsD;AACxD;;AAEA;EACE,kDAAkD;AACpD;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,sCAAsC;IACtC,YAAY;EACd;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".buttonSecondary {\n  background: white;\n  color: var(--color-primary);\n  border: 2px solid var(--color-primary);\n  text-transform: var(--button-secondary-text-transform);\n}\n\n.buttonSecondary:focus {\n  box-shadow: var(--form-control-focused-box-shadow);\n}\n\n.buttonSecondary:not(:disabled):active {\n  opacity: 0.8;\n}\n\n@media (hover: hover) {\n  .buttonSecondary:not(:disabled):hover {\n    background-color: var(--color-primary);\n    color: white;\n  }\n\n  .buttonSecondary:not(:disabled):active {\n    opacity: 0.8;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonSecondary": "ButtonSecondary_buttonSecondary__1_n_P"
};
export default ___CSS_LOADER_EXPORT___;
