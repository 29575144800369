// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ErrorBoundary_root__2lflN {\n  margin: 20vh var(--sp4);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.ErrorBoundary_heading__14vwU {\n  font-size: var(--fs6);\n  color: var(--color-neutral-900);\n  font-weight: 700;\n  margin-block-end: var(--sp3);\n}\n\n.ErrorBoundary_subHeading__3qPGv {\n  font-size: var(--fs4);\n  color: var(--color-neutral-600);\n  margin-block-end: var(--sp5);\n}\n\n@media (min-width: 768px) {\n  .ErrorBoundary_root__2lflN {\n    margin: 20vh auto;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/ErrorBoundary.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,+BAA+B;EAC/B,gBAAgB;EAChB,4BAA4B;AAC9B;;AAEA;EACE,qBAAqB;EACrB,+BAA+B;EAC/B,4BAA4B;AAC9B;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".root {\n  margin: 20vh var(--sp4);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.heading {\n  font-size: var(--fs6);\n  color: var(--color-neutral-900);\n  font-weight: 700;\n  margin-block-end: var(--sp3);\n}\n\n.subHeading {\n  font-size: var(--fs4);\n  color: var(--color-neutral-600);\n  margin-block-end: var(--sp5);\n}\n\n@media (min-width: 768px) {\n  .root {\n    margin: 20vh auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ErrorBoundary_root__2lflN",
	"heading": "ErrorBoundary_heading__14vwU",
	"subHeading": "ErrorBoundary_subHeading__3qPGv"
};
export default ___CSS_LOADER_EXPORT___;
