// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout_main__t9xuK {\n}\n\n.Layout_limit__xxfan {\n  margin: 0 auto;\n  max-width: var(--pg-width-content-limit);\n}\n\n.Layout_navContainer__1XQX0 {\n  padding: var(--sp2) var(--sp4);\n}\n\n.Layout_backLinkContainer__3b69i {\n  margin: var(--sp4);\n}\n\n@media (min-width: 768px) {\n  .Layout_backLinkContainer__3b69i {\n    margin-block-end: 0;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/Layout.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,cAAc;EACd,wCAAwC;AAC1C;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".main {\n}\n\n.limit {\n  margin: 0 auto;\n  max-width: var(--pg-width-content-limit);\n}\n\n.navContainer {\n  padding: var(--sp2) var(--sp4);\n}\n\n.backLinkContainer {\n  margin: var(--sp4);\n}\n\n@media (min-width: 768px) {\n  .backLinkContainer {\n    margin-block-end: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "Layout_main__t9xuK",
	"limit": "Layout_limit__xxfan",
	"navContainer": "Layout_navContainer__1XQX0",
	"backLinkContainer": "Layout_backLinkContainer__3b69i"
};
export default ___CSS_LOADER_EXPORT___;
