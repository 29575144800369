// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._CircularProgress_circularProgress__3fbFI {\n  height: 36px;\n  width: 36px;\n}\n\n._CircularProgress_circularProgressSvg__gHQ2Q {\n  animation-name: _CircularProgress_animation_circular_progress__1AN3h;\n  animation-duration: 0.75s;\n  animation-timing-function: linear;\n  animation-iteration-count: infinite;\n}\n\n@keyframes _CircularProgress_animation_circular_progress__1AN3h {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/lib/_CircularProgress.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,oEAA2C;EAC3C,yBAAyB;EACzB,iCAAiC;EACjC,mCAAmC;AACrC;;AAEA;EACE;IAEE,uBAAuB;EACzB;EACA;IAEE,yBAAyB;EAC3B;AACF","sourcesContent":[".circularProgress {\n  height: 36px;\n  width: 36px;\n}\n\n.circularProgressSvg {\n  animation-name: animation_circular_progress;\n  animation-duration: 0.75s;\n  animation-timing-function: linear;\n  animation-iteration-count: infinite;\n}\n\n@keyframes animation_circular_progress {\n  0% {\n    -webkit-transform: rotate(0deg);\n    transform: rotate(0deg);\n  }\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circularProgress": "_CircularProgress_circularProgress__3fbFI",
	"circularProgressSvg": "_CircularProgress_circularProgressSvg__gHQ2Q",
	"animation_circular_progress": "_CircularProgress_animation_circular_progress__1AN3h"
};
export default ___CSS_LOADER_EXPORT___;
