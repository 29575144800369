// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ErrorMessage_root__1i-iT {\n  display: grid;\n  justify-content: center;\n  align-items: center;\n  margin: var(--sp6) var(--pg-margin-horizontal);\n}\n\n.ErrorMessage_inner__2sUtl {\n  margin: 0 auto;\n  max-width: 500px;\n  display: grid;\n  justify-content: center;\n  justify-items: center;\n  align-items: center;\n  padding: var(--sp5) var(--sp7);\n  border-radius: var(--br2);\n  background-color: var(--color-danger-000);\n  border: 1px solid var(--color-danger-100);\n}\n\n.ErrorMessage_text__4LlHD {\n  font-size: var(--fs4);\n  font-weight: 500;\n  color: var(--color-danger-500);\n}\n", "",{"version":3,"sources":["webpack://src/components/ErrorMessage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,8CAA8C;AAChD;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,qBAAqB;EACrB,mBAAmB;EACnB,8BAA8B;EAC9B,yBAAyB;EACzB,yCAAyC;EACzC,yCAAyC;AAC3C;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,8BAA8B;AAChC","sourcesContent":[".root {\n  display: grid;\n  justify-content: center;\n  align-items: center;\n  margin: var(--sp6) var(--pg-margin-horizontal);\n}\n\n.inner {\n  margin: 0 auto;\n  max-width: 500px;\n  display: grid;\n  justify-content: center;\n  justify-items: center;\n  align-items: center;\n  padding: var(--sp5) var(--sp7);\n  border-radius: var(--br2);\n  background-color: var(--color-danger-000);\n  border: 1px solid var(--color-danger-100);\n}\n\n.text {\n  font-size: var(--fs4);\n  font-weight: 500;\n  color: var(--color-danger-500);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ErrorMessage_root__1i-iT",
	"inner": "ErrorMessage_inner__2sUtl",
	"text": "ErrorMessage_text__4LlHD"
};
export default ___CSS_LOADER_EXPORT___;
