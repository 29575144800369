// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderMenuButton_menuButton__3u2VS {\n  background-color: transparent;\n  border: none;\n  color: white;\n  font: inherit;\n  font-size: var(--fs1);\n  line-height: 1;\n  cursor: pointer;\n  margin: 0;\n  padding: var(--sp1) 0;\n  outline: none;\n}\n\n.HeaderMenuButton_menuButton__3u2VS svg {\n  font-size: var(--fs5);\n}\n\n.HeaderMenuButton_menuButton__3u2VS:hover {\n  opacity: 0.8;\n}\n\n.HeaderMenuButton_menuButtonArrow__2jwKU {\n  margin-inline-start: 5px;\n}\n\n.HeaderMenuButton_menuButtonLayout__2SSBE {\n  display: inline-flex;\n  flex: none;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n}\n", "",{"version":3,"sources":["webpack://src/components/HeaderMenuButton.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,qBAAqB;EACrB,cAAc;EACd,eAAe;EACf,SAAS;EACT,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,oBAAoB;EACpB,UAAU;EACV,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".menuButton {\n  background-color: transparent;\n  border: none;\n  color: white;\n  font: inherit;\n  font-size: var(--fs1);\n  line-height: 1;\n  cursor: pointer;\n  margin: 0;\n  padding: var(--sp1) 0;\n  outline: none;\n}\n\n.menuButton svg {\n  font-size: var(--fs5);\n}\n\n.menuButton:hover {\n  opacity: 0.8;\n}\n\n.menuButtonArrow {\n  margin-inline-start: 5px;\n}\n\n.menuButtonLayout {\n  display: inline-flex;\n  flex: none;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuButton": "HeaderMenuButton_menuButton__3u2VS",
	"menuButtonArrow": "HeaderMenuButton_menuButtonArrow__2jwKU",
	"menuButtonLayout": "HeaderMenuButton_menuButtonLayout__2SSBE"
};
export default ___CSS_LOADER_EXPORT___;
