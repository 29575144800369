// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BackLink_layout__2OCFC {\n  display: grid;\n  grid-auto-flow: column;\n  justify-content: start;\n  justify-items: start;\n  align-items: center;\n  grid-gap: var(--sp1);\n  gap: var(--sp1);\n  color: var(--color-neutral-500);\n  font-size: var(--fs4);\n  font-weight: 700;\n}\n\n.BackLink_layout__2OCFC svg {\n  margin-block-start: -2px;\n}\n", "",{"version":3,"sources":["webpack://src/components/lib/BackLink.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,oBAAoB;EACpB,mBAAmB;EACnB,oBAAe;EAAf,eAAe;EACf,+BAA+B;EAC/B,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".layout {\n  display: grid;\n  grid-auto-flow: column;\n  justify-content: start;\n  justify-items: start;\n  align-items: center;\n  gap: var(--sp1);\n  color: var(--color-neutral-500);\n  font-size: var(--fs4);\n  font-weight: 700;\n}\n\n.layout svg {\n  margin-block-start: -2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "BackLink_layout__2OCFC"
};
export default ___CSS_LOADER_EXPORT___;
