// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonDanger_buttonDanger__2-xZk {\n  background: var(--color-danger-600);\n  color: white;\n  font-weight: 500;\n  border: 1px solid var(--color-danger-600);\n  text-transform: var(--button-danger-text-transform);\n}\n\n.ButtonDanger_buttonDanger__2-xZk:focus {\n  box-shadow: var(--color-danger-100) 0 0 0 4px;\n}\n\n.ButtonDanger_buttonDanger__2-xZk:not(:disabled):active {\n  opacity: 0.8;\n}\n\n@media (hover: hover) {\n  .ButtonDanger_buttonDanger__2-xZk:not(:disabled):hover {\n    opacity: 0.9;\n  }\n\n  .ButtonDanger_buttonDanger__2-xZk:not(:disabled):active {\n    opacity: 0.8;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/lib/ButtonDanger.module.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,YAAY;EACZ,gBAAgB;EAChB,yCAAyC;EACzC,mDAAmD;AACrD;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".buttonDanger {\n  background: var(--color-danger-600);\n  color: white;\n  font-weight: 500;\n  border: 1px solid var(--color-danger-600);\n  text-transform: var(--button-danger-text-transform);\n}\n\n.buttonDanger:focus {\n  box-shadow: var(--color-danger-100) 0 0 0 4px;\n}\n\n.buttonDanger:not(:disabled):active {\n  opacity: 0.8;\n}\n\n@media (hover: hover) {\n  .buttonDanger:not(:disabled):hover {\n    opacity: 0.9;\n  }\n\n  .buttonDanger:not(:disabled):active {\n    opacity: 0.8;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonDanger": "ButtonDanger_buttonDanger__2-xZk"
};
export default ___CSS_LOADER_EXPORT___;
