// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonNeutral_buttonNeutral__aMleJ {\n  background-color: var(--color-neutral-000);\n  border: 1px solid var(--color-neutral-200);\n  color: var(--color-neutral-700);\n  text-transform: var(--button-neutral-text-transform);\n}\n\n.ButtonNeutral_buttonNeutral__aMleJ:focus {\n  box-shadow: var(--color-neutral-100) 0 0 0 4px;\n}\n\n.ButtonNeutral_buttonNeutral__aMleJ:not(:disabled):active {\n  opacity: 0.7;\n}\n\n@media (hover: hover) {\n  .ButtonNeutral_buttonNeutral__aMleJ:not(:disabled):hover {\n    opacity: 0.8;\n  }\n\n  .ButtonNeutral_buttonNeutral__aMleJ:not(:disabled):active {\n    opacity: 0.7;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/lib/ButtonNeutral.module.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,0CAA0C;EAC1C,+BAA+B;EAC/B,oDAAoD;AACtD;;AAEA;EACE,8CAA8C;AAChD;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".buttonNeutral {\n  background-color: var(--color-neutral-000);\n  border: 1px solid var(--color-neutral-200);\n  color: var(--color-neutral-700);\n  text-transform: var(--button-neutral-text-transform);\n}\n\n.buttonNeutral:focus {\n  box-shadow: var(--color-neutral-100) 0 0 0 4px;\n}\n\n.buttonNeutral:not(:disabled):active {\n  opacity: 0.7;\n}\n\n@media (hover: hover) {\n  .buttonNeutral:not(:disabled):hover {\n    opacity: 0.8;\n  }\n\n  .buttonNeutral:not(:disabled):active {\n    opacity: 0.7;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonNeutral": "ButtonNeutral_buttonNeutral__aMleJ"
};
export default ___CSS_LOADER_EXPORT___;
