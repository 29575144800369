// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FullScreenLoading_root__1XSoi {\n  display: grid;\n  justify-content: center;\n  align-items: center;\n  margin: var(--sp6) var(--pg-margin-horizontal);\n}\n", "",{"version":3,"sources":["webpack://src/components/FullScreenLoading.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,8CAA8C;AAChD","sourcesContent":[".root {\n  display: grid;\n  justify-content: center;\n  align-items: center;\n  margin: var(--sp6) var(--pg-margin-horizontal);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "FullScreenLoading_root__1XSoi"
};
export default ___CSS_LOADER_EXPORT___;
