// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonTransparentNeutral_buttonTransparentNeutral__2gvaX {\n  background: transparent;\n  color: var(--color-neutral-700);\n  border-radius: var(--button-border-radius);\n  box-shadow: none;\n  text-transform: var(--button-transparent-neutral-text-transform);\n}\n\n.ButtonTransparentNeutral_buttonTransparentNeutral__2gvaX:focus {\n  box-shadow: var(--color-neutral-100) 0 0 0 4px;\n}\n\n.ButtonTransparentNeutral_buttonTransparentNeutral__2gvaX:not(:disabled):active {\n  opacity: 0.8;\n}\n\n@media (hover: hover) {\n  .ButtonTransparentNeutral_buttonTransparentNeutral__2gvaX:not(:disabled):hover {\n    background-color: var(--color-neutral-100);\n  }\n\n  .ButtonTransparentNeutral_buttonTransparentNeutral__2gvaX:not(:disabled):active {\n    opacity: 0.8;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/lib/ButtonTransparentNeutral.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,+BAA+B;EAC/B,0CAA0C;EAC1C,gBAAgB;EAChB,gEAAgE;AAClE;;AAEA;EACE,8CAA8C;AAChD;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,0CAA0C;EAC5C;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".buttonTransparentNeutral {\n  background: transparent;\n  color: var(--color-neutral-700);\n  border-radius: var(--button-border-radius);\n  box-shadow: none;\n  text-transform: var(--button-transparent-neutral-text-transform);\n}\n\n.buttonTransparentNeutral:focus {\n  box-shadow: var(--color-neutral-100) 0 0 0 4px;\n}\n\n.buttonTransparentNeutral:not(:disabled):active {\n  opacity: 0.8;\n}\n\n@media (hover: hover) {\n  .buttonTransparentNeutral:not(:disabled):hover {\n    background-color: var(--color-neutral-100);\n  }\n\n  .buttonTransparentNeutral:not(:disabled):active {\n    opacity: 0.8;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonTransparentNeutral": "ButtonTransparentNeutral_buttonTransparentNeutral__2gvaX"
};
export default ___CSS_LOADER_EXPORT___;
