// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._ButtonActivityIndicator_buttonActivityIndicatorRoot__2qwBs {\n  height: 30px;\n  width: 30px;\n}\n\n._ButtonActivityIndicator_buttonActivityIndicatorSvg__3Q8CW {\n  animation-name: _ButtonActivityIndicator_button_activity_indicator_animation_circular_progress__3h90t;\n  animation-duration: 0.75s;\n  animation-timing-function: linear;\n  animation-iteration-count: infinite;\n}\n\n._ButtonActivityIndicator_buttonActivityIndicatorSvg__3Q8CW > circle {\n  stroke: currentColor;\n}\n\n@keyframes _ButtonActivityIndicator_button_activity_indicator_animation_circular_progress__3h90t {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/lib/_ButtonActivityIndicator.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,qGAAqE;EACrE,yBAAyB;EACzB,iCAAiC;EACjC,mCAAmC;AACrC;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE;IAEE,uBAAuB;EACzB;EACA;IAEE,yBAAyB;EAC3B;AACF","sourcesContent":[".buttonActivityIndicatorRoot {\n  height: 30px;\n  width: 30px;\n}\n\n.buttonActivityIndicatorSvg {\n  animation-name: button_activity_indicator_animation_circular_progress;\n  animation-duration: 0.75s;\n  animation-timing-function: linear;\n  animation-iteration-count: infinite;\n}\n\n.buttonActivityIndicatorSvg > circle {\n  stroke: currentColor;\n}\n\n@keyframes button_activity_indicator_animation_circular_progress {\n  0% {\n    -webkit-transform: rotate(0deg);\n    transform: rotate(0deg);\n  }\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonActivityIndicatorRoot": "_ButtonActivityIndicator_buttonActivityIndicatorRoot__2qwBs",
	"buttonActivityIndicatorSvg": "_ButtonActivityIndicator_buttonActivityIndicatorSvg__3Q8CW",
	"button_activity_indicator_animation_circular_progress": "_ButtonActivityIndicator_button_activity_indicator_animation_circular_progress__3h90t"
};
export default ___CSS_LOADER_EXPORT___;
