// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonSecondaryDanger_buttonSecondaryDanger__3aMCD {\n  background-color: var(--color-neutral-000);\n  border: 1px solid var(--color-neutral-200);\n  color: var(--color-danger-600);\n  text-transform: var(--button-secondary-danger-text-transform);\n}\n\n.ButtonSecondaryDanger_buttonSecondaryDanger__3aMCD:focus {\n  box-shadow: var(--color-neutral-100) 0 0 0 4px;\n}\n\n.ButtonSecondaryDanger_buttonSecondaryDanger__3aMCD:not(:disabled):active {\n  opacity: 0.7;\n}\n\n@media (hover: hover) {\n  .ButtonSecondaryDanger_buttonSecondaryDanger__3aMCD:not(:disabled):hover {\n    opacity: 0.8;\n  }\n\n  .ButtonSecondaryDanger_buttonSecondaryDanger__3aMCD:not(:disabled):active {\n    opacity: 0.7;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/lib/ButtonSecondaryDanger.module.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,0CAA0C;EAC1C,8BAA8B;EAC9B,6DAA6D;AAC/D;;AAEA;EACE,8CAA8C;AAChD;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".buttonSecondaryDanger {\n  background-color: var(--color-neutral-000);\n  border: 1px solid var(--color-neutral-200);\n  color: var(--color-danger-600);\n  text-transform: var(--button-secondary-danger-text-transform);\n}\n\n.buttonSecondaryDanger:focus {\n  box-shadow: var(--color-neutral-100) 0 0 0 4px;\n}\n\n.buttonSecondaryDanger:not(:disabled):active {\n  opacity: 0.7;\n}\n\n@media (hover: hover) {\n  .buttonSecondaryDanger:not(:disabled):hover {\n    opacity: 0.8;\n  }\n\n  .buttonSecondaryDanger:not(:disabled):active {\n    opacity: 0.7;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonSecondaryDanger": "ButtonSecondaryDanger_buttonSecondaryDanger__3aMCD"
};
export default ___CSS_LOADER_EXPORT___;
