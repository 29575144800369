// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonPrimary_buttonPrimary__RKKZW {\n  background: var(--color-primary);\n  color: white;\n  font-weight: 500;\n  border: 1px solid var(--color-primary);\n  text-transform: var(--button-primary-text-transform);\n}\n\n.ButtonPrimary_buttonPrimary__RKKZW:focus {\n  box-shadow: var(--form-control-focused-box-shadow);\n}\n\n.ButtonPrimary_buttonPrimary__RKKZW:not(:disabled):active {\n  opacity: 0.8;\n}\n\n@media (hover: hover) {\n  .ButtonPrimary_buttonPrimary__RKKZW:not(:disabled):hover {\n    opacity: 0.9;\n  }\n\n  .ButtonPrimary_buttonPrimary__RKKZW:not(:disabled):active {\n    opacity: 0.8;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/lib/ButtonPrimary.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,YAAY;EACZ,gBAAgB;EAChB,sCAAsC;EACtC,oDAAoD;AACtD;;AAEA;EACE,kDAAkD;AACpD;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".buttonPrimary {\n  background: var(--color-primary);\n  color: white;\n  font-weight: 500;\n  border: 1px solid var(--color-primary);\n  text-transform: var(--button-primary-text-transform);\n}\n\n.buttonPrimary:focus {\n  box-shadow: var(--form-control-focused-box-shadow);\n}\n\n.buttonPrimary:not(:disabled):active {\n  opacity: 0.8;\n}\n\n@media (hover: hover) {\n  .buttonPrimary:not(:disabled):hover {\n    opacity: 0.9;\n  }\n\n  .buttonPrimary:not(:disabled):active {\n    opacity: 0.8;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonPrimary": "ButtonPrimary_buttonPrimary__RKKZW"
};
export default ___CSS_LOADER_EXPORT___;
