// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MenuPopover_popoverInner__1NLpb {\n  font: inherit;\n  border: none;\n  border-radius: var(--menu-button-menu-popover-border-radius);\n  box-shadow: var(--box-shadow-1);\n  padding: var(--sp2) 0;\n  background: white;\n  width: 300px;\n}\n\n.MenuPopover_popoverInner__1NLpb [data-reach-menu-items] {\n  font: inherit;\n  border: none;\n  background: inherit;\n  outline: none;\n  padding: 0;\n}\n", "",{"version":3,"sources":["webpack://src/components/lib/MenuPopover.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,4DAA4D;EAC5D,+BAA+B;EAC/B,qBAAqB;EACrB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,UAAU;AACZ","sourcesContent":[".popoverInner {\n  font: inherit;\n  border: none;\n  border-radius: var(--menu-button-menu-popover-border-radius);\n  box-shadow: var(--box-shadow-1);\n  padding: var(--sp2) 0;\n  background: white;\n  width: 300px;\n}\n\n.popoverInner [data-reach-menu-items] {\n  font: inherit;\n  border: none;\n  background: inherit;\n  outline: none;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popoverInner": "MenuPopover_popoverInner__1NLpb"
};
export default ___CSS_LOADER_EXPORT___;
