// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Pressable_pressable__MmHyH {\n  display: block;\n  color: inherit;\n  font: inherit;\n  text-decoration: none;\n  border: none;\n  background: transparent;\n  padding: 0;\n  margin: 0;\n  text-decoration: none;\n  cursor: pointer;\n  outline: none;\n}\n\n.Pressable_pressable__MmHyH:active {\n  opacity: 0.7;\n}\n\n@media (hover: hover) {\n  .Pressable_pressable__MmHyH:hover {\n    opacity: 0.8;\n  }\n\n  .Pressable_pressable__MmHyH:active {\n    opacity: 0.7;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/lib/Pressable.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,cAAc;EACd,aAAa;EACb,qBAAqB;EACrB,YAAY;EACZ,uBAAuB;EACvB,UAAU;EACV,SAAS;EACT,qBAAqB;EACrB,eAAe;EACf,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".pressable {\n  display: block;\n  color: inherit;\n  font: inherit;\n  text-decoration: none;\n  border: none;\n  background: transparent;\n  padding: 0;\n  margin: 0;\n  text-decoration: none;\n  cursor: pointer;\n  outline: none;\n}\n\n.pressable:active {\n  opacity: 0.7;\n}\n\n@media (hover: hover) {\n  .pressable:hover {\n    opacity: 0.8;\n  }\n\n  .pressable:active {\n    opacity: 0.7;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pressable": "Pressable_pressable__MmHyH"
};
export default ___CSS_LOADER_EXPORT___;
